import React from "react";

const LeftIcon = ({
  icon,
  className = "",
  size = "md",
}: {
  icon: string;
  className?: string;
  size?: "sm" | "md" | "lg";
}) => (
  <span
    className={`p-4 flex items-center justify-center flex-shrink-0 ${
      size === "lg" ? "fs30" : size === "md" ? "fs26" : "fs24"
    } ${className}`}
  >
    <span className={`darkBlue cIcon-${icon}`} aria-hidden />
  </span>
);

export default React.memo(LeftIcon);
