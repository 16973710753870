import {mods} from "../components/_common/_constants";
import {RootStateLocation} from "../store/types";

export const regionFilter = (region: string) => (loc: RootStateLocation) =>
  loc.region.slug === region;

export const locationsByRegion = (
  locations: RootStateLocation[],
  selectedRegion: string,
): RootStateLocation[] => {
  if (!selectedRegion) return locations.filter(l => l.id !== mods.video);
  return locations.filter(l => l.id !== mods.video).filter(regionFilter(selectedRegion)) || [];
};
