import React from "react";

const Tag = ({className = "", children}: {className?: string; children?: string}): JSX.Element => (
  <em
    className={`font-isb bg-lighterGreen py-1 px-2 br4 not-italic whitespace-nowrap empty:hidden ${className}`}
  >
    {children}
  </em>
);

export default React.memo(Tag);
