import React from "react";

import {CardRow} from "../Composition/CardRow";
import Tag from "../Composition/Tag";
import SlotTime from "./SlotTime";

const Tags = ({
  turnaround,
  price,
  isSlotLoading,
  readableSlotTime,
}: {
  turnaround?: string;
  price?: string;
  isSlotLoading?: boolean;
  readableSlotTime?: string;
}) => (
  <CardRow>
    <Tag>{turnaround}</Tag>
    <strong className="font-isb text-sm empty:hidden">{price}</strong>
    <SlotTime readableSlotTime={readableSlotTime} isLoading={isSlotLoading} />
  </CardRow>
);

export default React.memo(Tags);
