import {debounce} from "lodash";
import {MutableRefObject, useEffect, useMemo, useRef} from "react";

import {dly} from "../components/_common/Carbon";
import {getDevice} from "../utils/browser-storage/device";

const scrollTopMargin = 1; // amount of px to scroll

/*
 overscroll-behavior is not supported on IOS 15 yet but coming to 16.
 While this isnt supported in IOS, adding a temporary hacky solution to move scroll a bit on
 load and on touch to scroll area so scroll is not locked when visitor starts scrolling down
 ref: https://caniuse.com/css-overscroll-behavior
 */

// TODO @geryit: Remove this hack when IOS 16 is a major OS

export const useIOSScroller = <T>(content?: T): MutableRefObject<HTMLDivElement> => {
  const {osType, osVersion} = getDevice() || {};
  const isIOS16AndBelow = osType === "IOS" && osVersion && (parseInt(osVersion, 10) || 0) < 16;
  const scrollRef = useRef(null);
  const handleScroll = useMemo(
    () =>
      debounce(e => {
        if (e.scrollTop === 0) {
          // check if scroll is on top when content is loaded
          e.scrollTop = scrollTopMargin; // move scroll down
        } else if (e.target?.scrollTop === 0) {
          // check if scroll is on top when scroll event is finished
          e.target.scrollTop = scrollTopMargin; // move scroll down
        }
      }, 150),
    [],
  );

  useEffect(() => {
    if (!isIOS16AndBelow) return; // disabled in all except IOS 16 and below

    const reasonsListEl = scrollRef.current;
    if (!reasonsListEl) return;

    dly(() => {
      if (reasonsListEl) {
        handleScroll(reasonsListEl);
      }
    }, 500); // wait for content to load, otherwise this wont work

    // @ts-expect-error TS2339: Property 'addEventListener' does not exist on type 'never'.
    reasonsListEl?.addEventListener("scroll", handleScroll);
    return () => {
      // @ts-expect-error TS2339: Property 'removeEventListener' does not exist on type 'never'.
      reasonsListEl?.removeEventListener("keydown", handleScroll);
    };
  }, [scrollRef, content, handleScroll, isIOS16AndBelow]);

  // @ts-expect-error TS2322: Type 'MutableRefObject<null>' is not assignable to type 'MutableRefObject<HTMLDivElement>'.
  return scrollRef;
};
