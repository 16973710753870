import {PracticeApptReason} from "@services/monolith/appointmentReasons";
import {ApptSlot} from "@services/monolith/availability";
import {useCallback} from "react";

import {fetchReasonBySlug} from "../_services/api";
import {
  maybePairLocsWithSlots,
  reduceLocSlotPairsToSoonestSlot,
} from "../components/v5/Locations/usePairLocsWithSlots";
import {QueryState, useQueryController} from "../hooks/useQueryController";
import {getSoonestVirtualSlot} from "../hooks/useSoonestVirtualSlot";
import {useTypedSelector} from "../store";
import {selectSelectedRegion} from "../store/slices/userLocation";
import {RootStateLocation} from "../store/types";
import {doesLocationHaveASpecialtyId} from "../utils/filterLocationsBySpecialtyId";
import {regionFilter} from "../utils/locationsByRegion";
import {getStateCodeFromRegionSlug} from "../utils/stateUtils";

export const isReasonOnlyVirtual = (practiceApptReason: PracticeApptReason): boolean =>
  practiceApptReason &&
  practiceApptReason.supportsVideoVisit.compact().length ===
    practiceApptReason.supportsVideoVisit.length;

const fetchSoonestSlotByReasonSlug = (
  apptReason: PracticeApptReason,
  locations: RootStateLocation[],
  selectedRegion: string,
) =>
  isReasonOnlyVirtual(apptReason)
    ? getSoonestVirtualSlot(
        apptReason.specialtyIds,
        // @ts-expect-error TS2345: Argument of type 'string | null' is not assignable to parameter of type 'string'.
        getStateCodeFromRegionSlug(selectedRegion),
        apptReason.id,
      )
    : maybePairLocsWithSlots(locations, apptReason).then(reduceLocSlotPairsToSoonestSlot);

const reduceApptSlotsToSoonest = (apptSlots: ApptSlot[]): ApptSlot =>
  apptSlots.reduce<ApptSlot>((acc, next) => {
    const isNextValid = Number.isInteger(next?.time);
    const isFirstValid = acc === null;
    const nextIsSooner = next?.time < acc?.time;
    return isNextValid && (isFirstValid || nextIsSooner) ? next : acc;
    // @ts-expect-error TS2345: Argument of type 'null' is not assignable to parameter of type 'ApptSlot'.
  }, null);

export const useSoonestSlotByReasonSlugsQuery = (
  slugs: string[],
  selectedLocation?: RootStateLocation,
  skip = false,
): QueryState<ApptSlot> => {
  const storeLocations = useTypedSelector(state => state.config.locations);
  const selectedRegion = useTypedSelector(selectSelectedRegion);

  const fn = useCallback(
    () =>
      slugs
        .map(slug =>
          fetchReasonBySlug(slug).then(apptReason => {
            const locationsToCheck = selectedLocation
              ? [selectedLocation]
              : storeLocations
                  .filter(regionFilter(selectedRegion))
                  .filter(doesLocationHaveASpecialtyId(apptReason.specialtyIds))
                  .slice(0, 3);

            return fetchSoonestSlotByReasonSlug(apptReason, locationsToCheck, selectedRegion);
          }),
        )
        .sequence()
        // @ts-expect-error TS2345: Argument of type '(apptSlots: ApptSlot[]) => ApptSlot' is not assignable to parameter of type '(value: (ApptSlot | null)[]) => ApptSlot | PromiseLike<ApptSlot>'.
        .then(reduceApptSlotsToSoonest),
    [selectedLocation, selectedRegion, slugs, storeLocations],
  );

  // @ts-expect-error TS2322: Type 'QueryState<ApptSlot | null>' is not assignable to type 'QueryState<ApptSlot>'.
  return useQueryController({
    fn,
    cacheKey: JSON.stringify({
      slugs,
      selectedLocation: selectedLocation?.slug,
      selectedRegion,
    }),
    skip,
    ifRejectedValue: null,
    initialValue: null,
  });
};
