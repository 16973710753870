import React from "react";

type Props = {
  onClose: () => void;
  children: React.ReactNode;
  cypressKey: string;
};

const CloseSearchDialogButton: React.FC<Props> = ({onClose, children, cypressKey}) => (
  <button
    id="close-modal"
    className="otn brdn p-2 br2 df jcc aic cp bg-transparent top0 right0 relative z-10"
    onClick={onClose}
    data-cy={cypressKey}
  >
    {children}
  </button>
);

export default CloseSearchDialogButton;
