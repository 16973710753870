import {useCallback, useMemo} from "react";

import {QueryState, useQueryController} from "../hooks/useQueryController";
import {fetchCareEntries} from "../utils/cms/cms";
import {CareEntrySearchResult} from "../utils/cms/types";
import {noOp} from "../utils/noOp";
import tee from "../utils/promise/tee";

export const useSearchCareEntriesQuery = (
  query = "",
  {
    skip = false,
    isNoSymptomsSearched = false,
    queryAnalyticsReporter = noOp,
  }: {
    skip?: boolean;
    isNoSymptomsSearched?: boolean;
    queryAnalyticsReporter: (cares: CareEntrySearchResult[], showNoResults: boolean) => unknown;
  },
): QueryState<CareEntrySearchResult[]> => {
  const fn = useCallback(
    () =>
      (isNoSymptomsSearched ? Promise.resolve([]) : fetchCareEntries(query)).then(
        tee(results => {
          queryAnalyticsReporter(results, results.length === 0 && query.length > 1);
        }),
      ),
    [isNoSymptomsSearched, query, queryAnalyticsReporter],
  );

  const defaultValue = useMemo(() => [], []);

  return useQueryController({
    fn,
    cacheKey: query,
    skip,
    initialValue: defaultValue,
  });
};
