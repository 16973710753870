import React from "react";

import {Focusable} from "../../../Focusable";

const CloseTopicExpansionButton = ({
  onClick,
  children,
  cypressKey,
}: {
  children: React.ReactNode;
  onClick: () => void;
  cypressKey: string;
}): React.ReactElement => (
  <Focusable>
    <button
      onClick={onClick}
      className="border-none outline-none bg-transparent cursor-pointer p-2 flex items-center fs14 font-ir ls-3 whitespace-nowrap"
      data-cy={cypressKey}
    >
      {children}
    </button>
  </Focusable>
);

export default React.memo(CloseTopicExpansionButton);
