import {mergeProps} from "@react-aria/utils";
import React from "react";

type Props = React.HTMLAttributes<HTMLDivElement>;

const style = {top: "calc(15vh - 50px)"};

const DialogContent = (props: Props): React.ReactElement => {
  const mergedProps = mergeProps(
    {
      className:
        "px-4 sm:px-10 pt-5 pb-10 br2 flex flex-col gap-0.5 bg-white m-auto minw44 maxw44 minw0-sm w100vw-sm minh100vh-sm br0-sm absolute top0-f-sm left-0 right-0 overflow-hidden",
      style,
    },
    props,
  );
  return <div {...mergedProps} />;
};

export default React.memo(DialogContent);
