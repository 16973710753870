import memoizee from "memoizee";
import React, {useCallback, useEffect, useState} from "react";

import ListController from "../../../page-containers/get-care/components/ListController";

const memoizedSlice = memoizee(<T>(list: T[], start: number, stop: number) =>
  list.slice(start, stop),
);

/**
 * This utility hook gets the bounding list indexes for the care-discovery list
 * components in order to control how many results are being rendered. It also
 * provides control functions for updating the boundaries.s
 * @param minBindingThreshold If the list is shorter than this threshold (which defaults)
 * to 7, then `bindList` will not trim the list.
 */
export const useListBoundaryController = (
  listLength: number,
  initialStop: number,
  minBindingThreshold = 7,
) => {
  const getInitialBoundaries = useCallback((): [number, number] => [0, initialStop], [initialStop]);

  const [[start, stop], setRenderBoundaries] = useState(getInitialBoundaries());

  useEffect(() => {
    setRenderBoundaries(getInitialBoundaries());
  }, [listLength, initialStop, minBindingThreshold, getInitialBoundaries]);

  const showMore = useCallback(() => {
    setRenderBoundaries([start, Math.min(stop + 10, listLength)]);
  }, [listLength, start, stop]);

  const reset = useCallback(() => {
    setRenderBoundaries(getInitialBoundaries());
  }, [getInitialBoundaries]);

  const bindList = useCallback(
    <T>(list: T[]) => (listLength >= minBindingThreshold ? memoizedSlice(list, start, stop) : list),
    [listLength, minBindingThreshold, start, stop],
  );

  const isMaxed = stop >= listLength;

  const Controller = useCallback(
    ({onReset}: {onReset: () => void}): JSX.Element =>
      React.createElement(ListController, {
        start,
        stop,
        isMaxed,
        total: listLength,
        handleShowMoreClick: () => {
          if (isMaxed) {
            reset();
            onReset();
          } else {
            showMore();
          }
        },
      }),
    [isMaxed, listLength, reset, showMore, start, stop],
  );

  return {start, stop, isMaxed, showMore, bindList, reset, ListController: React.memo(Controller)};
};
