import {ApptSlot} from "@services/monolith/availability";
import memoizee from "memoizee";
import {useEffect, useState} from "react";
import {v4} from "uuid";

import {Address} from "../../../../_services/types";
import {BookingStep} from "../../../../page-containers/booking/utils/bookingStep";
import {getMilesAway} from "../../../../page-containers/booking/utils/webBookingAnalytics";
import {useTypedSelector} from "../../../../store";
import {selectSelectedRegion} from "../../../../store/slices/userLocation";
import {RootStateApptReason} from "../../../../store/types";
import {analytics} from "../../../../utils/analytics";
import {
  CustomCategory,
  EventCategory,
  EventType,
  Label,
} from "../../../../utils/analytics/constants";
import {queueEvent} from "../../../../utils/analytics/eventQueue";
import {useLatLong} from "../../../../utils/browser-storage/latLong";
import {CareEntrySearchResult} from "../../../../utils/cms/types";
import {v5Pages} from "../../../_common/_constants";
import {ExtendedCareEntrySearchResult} from "../../CareEntryRow";

const getCareEntryResultOptions = (
  results: CareEntrySearchResult[],
  reasons: RootStateApptReason[] | readonly RootStateApptReason[],
) =>
  results.slice(0, 20).map((result: ExtendedCareEntrySearchResult, idx) => {
    const apptReason = reasons.find(apptReason => apptReason.id === result.appointment_reason);
    return {
      apptReasonId: result.appointment_reason,
      apptReasonText: result.appointment_reason && result.name,
      topicText: !result.appointment_reason ? result.name : undefined,
      specialtyId: apptReason?.specialtyIds ? apptReason.specialtyIds[0] : undefined,
      rank: idx + 1,
    };
  });

export const buildSearchReporter =
  (
    query: string,
    reasons: RootStateApptReason[],
    noResultSuggestions?: ExtendedCareEntrySearchResult[],
  ) =>
  (results: CareEntrySearchResult[], showNoResults?: boolean) => {
    analytics.post({
      action: analytics.action.SEARCHED,
      category: analytics.category.APPOINTMENT_SCHEDULING,
      label: analytics.label.APPOINTMENT_REASON,
      extraData: {
        options: getCareEntryResultOptions(results, reasons),
        resultsCount: results.length,
        searchText: query,
        ...(showNoResults && {
          // @ts-expect-error TS2532: Object is possibly 'undefined'.
          noResultSuggestions: noResultSuggestions.map(result => result.appointment_reason),
        }),
      },
    });
  };

const getLabelAndIdFromPathname = (pathname?: string): [EventType, Label | undefined] => {
  switch (pathname) {
    case v5Pages.clinicDetails:
      return [
        EventType.AppointmentBookingWebsiteWidgetClicked,
        Label.AppointmentBookingWebsiteWidget,
      ];
    case v5Pages.home:
      return [EventType.AppointmentBookingHomeWidgetClicked, Label.AppointmentBookingHomeWidget];
    default:
      return [
        EventType.AppointmentBookingReasonWidgetClicked,
        Label.AppointmentBookingReasonWidget,
      ];
  }
};

export type CareDiscoveryRowClickReporter = ReturnType<typeof getSendClickedCareDiscoveryRowEvent>;

/**
 *
 * @param rowClickedTypeId `default` refers to non-modal results
 * @param pathname Only should be used with the `default` type
 */
export const getSendClickedCareDiscoveryRowEvent = (
  rowClickedTypeId: "default" | "searched" | "base",
  pathname?: string,
) =>
  memoizee(
    (
      result: CareEntrySearchResult,
      soonestSlot?: ApptSlot,
      rank?: number,
      flowId?: string,
      viewId?: string,
    ): void => {
      const [typeId, label] = (() => {
        switch (rowClickedTypeId) {
          case "searched":
            return [EventType.SearchedAppointmentReasonClicked, Label.SearchedAppointmentReason];
          case "base":
            return [EventType.AppointmentReasonClicked, Label.AppointmentReason];
          case "default":
            return getLabelAndIdFromPathname(pathname);
        }
      })();

      queueEvent({
        typeId,
        category: EventCategory.Custom,
        extraData: {
          category: CustomCategory.AppointmentScheduling,
          label,
          value: result.appointment_reason,
          flowId,
          viewId,
          rank,
          firstAvailability: soonestSlot?.time,
          specialtyId: soonestSlot?.specialtyIds?.[0],
        },
      });
    },
  );

const sendDefaultResultsViewedEvent = (
  id: string,
  results: CareEntrySearchResult[],
  reasons: RootStateApptReason[] | readonly RootStateApptReason[],
  selectedRegion: string,
  locationId?: string,
  milesAway?: number,
): void => {
  queueEvent({
    id,
    typeId: EventType.AppointmentReasonOptionsViewed,
    extraData: {
      category: CustomCategory.AppointmentScheduling,
      label: Label.AppointmentReasonOptions,
      bookingStep: BookingStep.REASON_SELECTION,
      selectedRegion,
      options: getCareEntryResultOptions(results, reasons),
      apptDetails:
        locationId || milesAway
          ? {
              locationId,
              milesAway,
            }
          : undefined,
    },
  });
};

export const useSendDefaultResultsViewedEvent = ({
  results,
  isLoading,
  location,
}: {
  results: ExtendedCareEntrySearchResult[];
  isLoading: boolean;
  location?: {id: string; address: Address};
}) => {
  const [viewId, setViewId] = useState<string>();
  const reasons = useTypedSelector(state => state.config.reasons);
  const selectedRegion = useTypedSelector(selectSelectedRegion);
  const userPosition = useLatLong();
  const milesAway = userPosition && location?.address && getMilesAway(userPosition, location);
  useEffect(() => {
    if (!isLoading && reasons && reasons.length > 0) {
      const newViewId = v4();
      sendDefaultResultsViewedEvent(
        newViewId,
        results,
        reasons,
        selectedRegion,
        location?.id,
        milesAway,
      );
      setViewId(newViewId);
    }
  }, [selectedRegion, reasons, location, isLoading, results, milesAway]);

  return viewId;
};
