import {FocusRing} from "@react-aria/focus";
import {mergeProps} from "@react-aria/utils";
import React, {ComponentProps} from "react";

type Props = Omit<ComponentProps<typeof FocusRing>, "focusRingClass"> & {
  focusStyleClass?: string;
};

const DEFAULT_FOCUS_STYLE = "focus-ring";
const DEFAULT_SELECTOR_STYLE = "aria-focus";

/**
 * Wrapper for react-aria `FocusRing` which automatically appends
 * focus classes for keyboard focused elements.
 */
export const Focusable: React.FC<Props> = ({
  focusStyleClass = DEFAULT_SELECTOR_STYLE,
  children,
  ...props
}) => {
  const newChildProps = mergeProps(children.props, {className: focusStyleClass});
  const child = React.cloneElement(children, newChildProps);
  const focusRingProps = mergeProps(props, {focusRingClass: DEFAULT_FOCUS_STYLE, children: child});
  return <FocusRing {...focusRingProps} />;
};
