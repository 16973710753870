import {useCallback, useMemo, useState} from "react";

export const useHoveredIndexController = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const unsetHovered = useCallback(() => setActiveIndex(null), [setActiveIndex]);

  return useMemo(
    () => ({
      unsetHovered,
      setHovered: setActiveIndex,
      activeIndex,
    }),
    [unsetHovered, activeIndex],
  );
};
