import {PracticeApptReasonSpecialty, VisibilityConfig} from "../_services/types";
import {apptReasonEntityCodesToHide} from "../constants/reasonCodes";

const isVisibilityConfigAcceptable = (visibilityConfig?: VisibilityConfig): boolean =>
  !visibilityConfig || !visibilityConfig.providerOnly;

const isValidSortIndex = Number.isInteger;

export const isReasonDiscoverable = (
  apptReason: {
    sortIndex?: number;
    practiceApptReasonSpecialties: PracticeApptReasonSpecialty[];
    code: string;
  },
  letNullSortIndex = false,
): boolean => {
  try {
    const isNotHiddenByCode = !apptReasonEntityCodesToHide.includes(apptReason.code);
    const hasValidSpecialty = apptReason.practiceApptReasonSpecialties.some(
      ({sortIndex, visibilityConfig}) =>
        (letNullSortIndex ||
          isValidSortIndex(apptReason.sortIndex) ||
          isValidSortIndex(sortIndex)) &&
        isVisibilityConfigAcceptable(visibilityConfig),
    );

    return hasValidSpecialty && isNotHiddenByCode;
  } catch {
    return false;
  }
};
