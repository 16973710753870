import {PracticeApptReasonSpecialty} from "@services/types";
import memoizee from "memoizee";

import {MsMap} from "../../constants/MsMap";
import {PracticeId} from "../../constants/practiceIds";
import monolith from "../../utils/fetch/fetches";

export function loadAppointmentReasons(
  practiceId: string = PracticeId.CARBON,
  video?: boolean,
  instant?: boolean,
) {
  return memoizedLoadApptReasonsExportedForTesting(practiceId, video, instant).catch(() => []);
}

// Exported only for testing. Prod code should call loadAppointmentReasons().
export const memoizedLoadApptReasonsExportedForTesting = memoizee(
  async (
    practiceId: string = PracticeId.CARBON,
    video?,
    instant?,
  ): Promise<PracticeApptReason[]> => {
    const response = await monolith()(
      `/hib/practices/${practiceId}/apptReasons/v2?${video ? "supportsVideoVisit=true" : ""}${
        instant ? "supportsInstantVisit=true" : ""
      }`,
    );
    return await response.json();
  },
  {
    maxAge: MsMap.ONE_MINUTE * 20,
    promise: true, // prevents caching bad response (exceptions)
  },
);

export type PracticeApptReason = {
  code: string; // 'Symptom:urinaryTractInfectionUTISymptoms',
  existingPatientDuration: number; // 30,
  requiresSupervisor: boolean; // false,
  practiceId: string; // '5bdaef44-8ff0-439f-99d7-3285afcc6911',
  externalId: number; // 425,
  typ: string; // 'Symptom',
  supportsVideoVisit: boolean[]; // [ true, true, false, false ],
  practiceApptReasonSpecialties: PracticeApptReasonSpecialty[];
  specialtyIds: string[];
  createdAt: number; // 1468965282085,
  apptReasonId: string; // 'b1c56f9a-c486-4922-b54c-4abcd27b9189',
  schemaId: string; // 'd577cb22-0a62-4378-a237-4ee5ff6c3617',
  name: string; // 'Urinary tract infection (UTI) symptoms',
  newPatientDuration: number; // 30,
  id: string; // 'b1c56f9a-c486-4922-b54c-4abcd27b9189',
  slug: string; // 'urinary-tract-infection-uti-symptoms',
  status: string; // 'AddedByStaff',
  supportsInstantVisit: boolean[]; // [ true, true, false, false ]
  iconName: string;
  description: string;
  reasonId?: string;
  sortIndex?: number;
};
