import {useTranslation} from "ni18n";
import React from "react";

type Props = {
  isNoSymptomsSearched?: boolean;
};

const NoResults: React.FC<Props> = ({isNoSymptomsSearched = false}) => {
  const i18n = useTranslation();
  return (
    <div className="brdb1 brd-gray100 df fdc">
      <span className="font-ir pt4 pb6 pt2-sm pb3-sm lh15">
        {isNoSymptomsSearched
          ? i18n.t("No symptoms? You can still book an appointment.")
          : i18n.t("We couldn’t find a match but you can still book an appointment.")}
      </span>
    </div>
  );
};

export default NoResults;
