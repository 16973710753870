import {useTranslation} from "ni18n";
import React from "react";

import ShowMore from "../../../components/v5/Reason/ShowMoreButton";

type Props = {
  start: number;
  stop: number;
  total: number;
  isMaxed: boolean;
  handleShowMoreClick: () => void;
};

const ListController = ({start, stop, total, isMaxed, handleShowMoreClick}: Props): JSX.Element => {
  const i18n = useTranslation();
  return (
    <div className="df aic fw pos-r zIndex2 pb2" style={{marginTop: -15}}>
      <span className="font-ir mr3 fs14">
        {i18n.t("{{firstNumber}} - {{secondNumber}} of {{total}}", {
          firstNumber: start + 1,
          secondNumber: stop,
          total,
        })}
      </span>
      <ShowMore
        onClick={handleShowMoreClick}
        text={isMaxed ? i18n.t("Show less") : i18n.t("Show more")}
        flipIcon={isMaxed}
      />
    </div>
  );
};

export default ListController;
