import memoizee from "memoizee";

import {RelatedCare, RelatedTopic} from "../../../../utils/cms/types";

export const getAllRelatedCares = memoizee(
  ({
    cares = [],
    related_topics = [],
  }: {
    cares?: RelatedCare[] | null;
    related_topics?: RelatedTopic[] | null;
  }): (RelatedCare | RelatedTopic)[] => [...(cares || []), ...(related_topics || [])],
);
