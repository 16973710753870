import {PracticeApptReason, loadAppointmentReasons} from "@services/monolith/appointmentReasons";
import {useEffect, useState} from "react";

import {RootState, actions, useTypedDispatch, useTypedSelector} from "../store";
import {RootStateApptReason} from "../store/types";
import {isReasonDiscoverable} from "../utils/isReasonDiscoverable";
import tee from "../utils/promise/tee";

const selectApptReasons = (state: RootState): RootStateApptReason[] | undefined =>
  state.config.reasons;

const pickApptReason = (reason: PracticeApptReason): RootStateApptReason => ({
  id: reason.id,
  slug: reason.slug,
  name: reason.name,
  iconName: reason.iconName,
  description: reason.description,
  specialtyIds: reason.specialtyIds,
  newPatientDuration: reason.newPatientDuration,
  supportsVideoVisit: reason.supportsVideoVisit,
  supportsInstantVisit: reason.supportsInstantVisit,
  practiceApptReasonSpecialties: reason.practiceApptReasonSpecialties,
  code: reason.code,
});

export const useLoadApptReasons = (): RootStateApptReason[] => {
  const [state, setState] = useState<RootStateApptReason[]>([]);
  const dispatch = useTypedDispatch();
  const maybeAlreadyLoadedReasons = useTypedSelector(selectApptReasons);
  const reasonsAreAlreadyLoaded =
    Array.isArray(maybeAlreadyLoadedReasons) && maybeAlreadyLoadedReasons.length > 0;

  useEffect(() => {
    if (!reasonsAreAlreadyLoaded) {
      loadAppointmentReasons()
        .then(reasons => reasons.filter(reason => isReasonDiscoverable(reason)))
        .then(reasons => reasons.map(pickApptReason))
        .then(tee(reasons => dispatch(actions.setConfig({reasons}))))
        .then(setState);
    }
  }, [reasonsAreAlreadyLoaded]);

  return state;
};
