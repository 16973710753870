import {colors} from "@c10h/colors";
import queryString from "query-string-for-all";

import {googleStaticMapMarker} from "../components/_common/_constants";
import {Coordinate} from "../store/types";

export const getGoogleMapsStaticImgSrc = (coordinate: Coordinate, googleApiKey: string): string => {
  const mapStyles = [
    // `feature:all|visibility:simplified`,
    "feature:poi|visibility:off",
    "feature:transit|visibility:off",
    `feature:water|element:geometry|color:0x${colors.darkerYellow.replace(/#/, "")}`,
    "feature:water|element:labels|visibility:off",
    `feature:road|color:0x${colors.darkerYellow.replace(/#/, "")}`,
    "feature:road|element:geometry|weight:1",
    `feature:road|element:labels.text.stroke|color:0x${colors.yellow.replace(/#/, "")}|weight:8`,
    `feature:landscape|color:0x${colors.yellow.replace(/#/, "")}`,
  ]
    .map(rule => `style=${rule}`)
    .join("&");

  const mapDimensions = {
    x: 150,
    y: 150,
  };

  return queryString.stringifyUrl(
    {
      url: "https://maps.googleapis.com/maps/api/staticmap",
      query: {
        key: googleApiKey,
        center: `${coordinate.x},${coordinate.y}`,
        zoom: 15,
        size: `${mapDimensions.x}x${mapDimensions.y}`,
        scale: 2,
        format: "jpg",
        markers: `anchor:32,64|icon:${googleStaticMapMarker}|${coordinate.x},${coordinate.y}|`,
        mapStyles,
      },
    },
    {encode: false},
  );
};
