type ViewportAxis = "vw" | "vh";
type ViewportDimension = `${number}${ViewportAxis}`;

export const modifyViewportDimension = (
  vpDimension: ViewportDimension,
  // @ts-expect-error TS7051: Parameter has a name but no type. Did you mean 'arg0: number'?
  modifier: (number) => number,
): ViewportDimension => {
  const axis = vpDimension.replace(/[0-9]/gi, "") as ViewportAxis;
  const originalVpDimension = parseInt(vpDimension.replace("vw", ""));
  const newVpDimension = modifier(originalVpDimension);
  return `${newVpDimension}${axis}`;
};
