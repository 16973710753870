import {LinkProps} from "next/link";
import React from "react";

import LinkOrButton from "../LinkOrButton";

const Interaction = ({
  children,
  href,
  onClick,
  isBold = false,
  disabled = false,
  cypressKey,
}: {
  isBold: boolean;
  href?: LinkProps["href"] | null;
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  cypressKey: string;
}): React.ReactElement => (
  <LinkOrButton
    data-cy={cypressKey}
    href={disabled ? null : href}
    onClick={onClick}
    disabled={disabled}
    className={`outline-none bg-transparent w-full border border-solid border-transparent hover:border-gray200 transition-colors inline-block text-left focus-bsDarkBlue3-hug px-0 py-0 hover-font-ib rounded-lg ${
      isBold ? "font-ib" : "font-ir"
    } ${disabled ? "cursor-default" : "cursor-pointer"}
    `}
  >
    {children}
  </LinkOrButton>
);

export default React.memo(Interaction);
