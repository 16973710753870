import {getGeoDistance} from "@services/api";

import {Address} from "../../../_services/types";
import {Coordinate} from "../../../store/types";

/**
 * Get the number of miles between the user's location and the clinic location.
 */
export function getMilesAway(
  userLocation: Coordinate,
  location: {address: Address},
): number | undefined {
  const latitude = location.address.latitude;
  const longitude = location.address.longitude;
  return userLocation && latitude && longitude
    ? getGeoDistance(userLocation.x, userLocation.y, latitude, longitude)
    : undefined;
}
