import React from "react";

import Glimmer from "../Glimmer";

const SlotTime = ({
  readableSlotTime,
  isLoading = false,
  className = "",
}: {
  readableSlotTime?: string;
  isLoading?: boolean;
  className?: string;
}) =>
  isLoading ? (
    <Glimmer height={20} width={100} />
  ) : (
    <strong
      data-cy="slot-time"
      className={`darkGreen font-isb text-sm ls-25 empty:hidden ${className}`}
    >
      {readableSlotTime}
    </strong>
  );

export default React.memo(SlotTime);
