import React from "react";
import reactStringReplace from "react-string-replace";

const Line = ({
  keyPrefix,
  text,
  query,
  className = "",
}: {
  keyPrefix: string;
  text: string;
  query?: string;
  className?: string;
}) => (
  <span
    className={`gray800 text-basic leading-none empty:hidden ${
      query ? "font-im" : "font-isb"
    } ${className}`}
  >
    {reactStringReplace(text, query, (match, i) => (
      <mark key={`${keyPrefix}-${match}-${i}`} className="bg-transparent font-isb">
        {match}
      </mark>
    ))}
  </span>
);

export default React.memo(Line);
