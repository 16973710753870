import {colors} from "@c10h/colors";
import React from "react";
import {transparent} from "src/utils/transparent";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string;
  flipIcon?: boolean;
};

const ShowMore: React.FC<Props> = ({text, flipIcon, style = {}, ...rest}) => (
  <button
    {...rest}
    className="df aic font-ib br5 p3 brdn otn cp focus-bsDarkBlue3 contrast-tb"
    style={{backgroundColor: transparent(colors.gray800, 10), ...style}}
  >
    <span className="fs14 gray800">{text}</span>
    <span
      className={`cIcon-dropdown-arrow-down fs12 ml2 t-200 gray800 ${flipIcon ? "rotate-180" : ""}`}
      aria-hidden
    />
  </button>
);

export default ShowMore;
