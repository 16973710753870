import React from "react";

export const CardRow: React.FC<React.HTMLAttributes<HTMLDivElement>> = React.memo(function CardRow({
  className = "",
  ...props
}) {
  return <div className={`df fw jcfs aic gg1 fs14 gray800 ${className}`} {...props} />;
});

export const CardRowGroup: React.FC<React.HTMLAttributes<HTMLDivElement>> = React.memo(
  function CardRowGroup({className = "", ...props}) {
    return <div className={`df wsnw jcfs aic gg1 fs14 gray800 ${className}`} {...props} />;
  },
);
