import {CustomCategory, EventCategory, EventType} from "../../../utils/analytics/constants";
import {queueEvent} from "../../../utils/analytics/eventQueue";
import {getTrafficId} from "../../../utils/browser-storage/trafficId";

export const sendClinicListRowClickEvent = (
  value: string,
  viewId?: string,
  locationId?: string,
  rank?: number,
  soonestSlotTime?: number,
  milesAway?: number,
  flowId?: string,
): void =>
  queueEvent({
    typeId: EventType.AppointmentBookingReasonWidgetClicked,
    extraData: {
      category: CustomCategory.AppointmentScheduling,
      value,
      locationId,
      soonestSlotTime,
      viewId,
      milesAway,
      flowId,
      rank,
    },
  });

export const sendInitiateWebSchedulingEvent = (flowId?: string): void =>
  queueEvent({
    category: EventCategory.Custom,
    typeId: EventType.AppointmentBookingStarted,
    extraData: {
      category: CustomCategory.AppointmentScheduling,
      flowId,
      trafficId: getTrafficId(),
    },
  });
