import {FeatureFlag} from "@services/featureFlagConstants";
import {useTranslation} from "ni18n";
import React from "react";
import {useFeatureFlag} from "src/hooks/useFeatureFlags";

import {Focusable} from "../../../Focusable";

const SearchDialogTrigger = ({onClick}: {onClick: () => void}): React.ReactElement => {
  const i18n = useTranslation();
  const CARE_DISCOVERY_TOPICS_SEARCH_ENABLED = useFeatureFlag<boolean>(
    FeatureFlag.CARE_DISCOVERY_TOPICS_SEARCH_ENABLED,
  );
  return CARE_DISCOVERY_TOPICS_SEARCH_ENABLED ? (
    <Focusable>
      <button
        id="modal-trigger"
        className="df aic brdn otn bg-gray100 p3 w100p br3 cp mb2"
        onClick={onClick}
        data-cy="care-discovery-search-modal-trigger"
      >
        <span className="cIcon-search mr3 fs26 text-gray600" aria-hidden />
        <span className="gray600 text-left self-center fs16 text-ellipsis font-ir overflow-hidden wsnw lc1 w-full">
          {i18n.t("Search symptoms or services")}
        </span>
      </button>
    </Focusable>
  ) : (
    // `null` causes React error. Using fragment to
    // avoid adding unnecessary elements to the DOM
    // eslint-disable-next-line
    <React.Fragment />
  );
};

export default SearchDialogTrigger;
