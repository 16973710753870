import React from "react";
import {Transition, TransitionStatus} from "react-transition-group";
import {EnterHandler, ExitHandler} from "react-transition-group/Transition";

import {cloneChildren} from "../../utils/cloneChildren";

const Position = {
  LEFT: {
    transform: "translateX(-100vw)",
    opacity: 0,
  },
  RIGHT: {
    transform: "translateX(100vw)",
    opacity: 0,
  },
  TOP: {
    transform: "translateY(-100vh)",
    opacity: 0,
  },
  BOTTOM: {
    transform: "translateY(100vh)",
    opacity: 0,
  },
  STATIC: {
    transform: "none",
    opacity: 1,
  },
} as const;

export enum TransitionPosition {
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
  BOTTOM = "bottom",
}

const getOffPosition = (from: TransitionPosition) => {
  switch (from) {
    case TransitionPosition.LEFT:
      return Position.LEFT;
    case TransitionPosition.RIGHT:
      return Position.RIGHT;
    case TransitionPosition.TOP:
      return Position.TOP;
    case TransitionPosition.BOTTOM:
      return Position.BOTTOM;
    default:
      return Position.LEFT;
  }
};

const getTransitionStyles = (state: TransitionStatus, from: TransitionPosition) => {
  const offPosition = getOffPosition(from);
  return {
    entering: Position.STATIC,
    entered: Position.STATIC,
    exiting: offPosition,
    exited: offPosition,
    unmounted: offPosition,
  }[state];
};

const Slide = ({
  duration = 150,
  from = TransitionPosition.LEFT,
  children,
  timeout = duration,
  ...transitionProps
}: {
  in?: boolean;
  duration?: number;
  timeout?: number;
  from?: TransitionPosition;
  appear?: boolean;
  unmountOnExit?: boolean;
  mountOnEnter?: boolean;
  children:
    | React.ReactElement<{style: React.CSSProperties}>
    | React.ReactElement<{style: React.CSSProperties}>[];
  onEnter?: EnterHandler<undefined>;
  onEntering?: EnterHandler<undefined>;
  onEntered?: EnterHandler<undefined>;
  onExit?: ExitHandler<undefined>;
  onExiting?: ExitHandler<undefined>;
  onExited?: ExitHandler<undefined>;
}): React.ReactElement | null => {
  const cloneChildrenWithStyles = (state: TransitionStatus) =>
    cloneChildren(children, () => ({
      style: {
        transition: `transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
        ...getTransitionStyles(state, from),
      },
    }));

  return (
    <Transition timeout={timeout} {...transitionProps}>
      {cloneChildrenWithStyles}
    </Transition>
  );
};

export default React.memo(Slide);
