import {colors} from "@c10h/colors";
import React, {useMemo} from "react";

import {useIOSScroller} from "../../../hooks/useIOSScroller";
import {transparent} from "../../../utils/transparent";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean;
  maxHeight: string;
  backgroundColor?: string;
  containerTag?: "div" | "ul";
  containerId?: string;
  hideGradient?: boolean;
};

const ScrollContainer: React.FC<Props> = React.memo(
  ({
    children,
    containerTag = "div",
    containerId,
    disabled,
    maxHeight,
    backgroundColor = colors.gray50,
    hideGradient,
    ...props
  }) => {
    const scrollRef = useIOSScroller(children);

    const scrollConstraintStyles = disabled
      ? {}
      : {
          maxHeight,
          overflowY: "auto",
          paddingRight: 4,
        };

    const inner = useMemo(
      () =>
        React.createElement(
          containerTag,
          {
            className: hideGradient ? "" : "pb8",
            id: containerId,
          },
          children,
        ),
      [containerTag, hideGradient, containerId, children],
    );

    return (
      <>
        <div
          className="pos-r overscroll-contain overflow-x-hidden"
          style={scrollConstraintStyles}
          {...props}
          ref={scrollRef}
        >
          {inner}
        </div>
        {!hideGradient && (
          <div
            className="minw10 pos-r contrast-hide"
            style={{
              width: "calc(100% - 10px)", // 10px scroll bar width
              marginTop: -40,
              height: 40,
              backgroundColor,
              background: `linear-gradient(0deg, ${backgroundColor}, ${transparent(
                backgroundColor,
                0,
              )})`,
            }}
          />
        )}
      </>
    );
  },
);

ScrollContainer.displayName = "ScrollContainer";

export default React.memo(ScrollContainer);
