import React from "react";

const anchorToTop =
  "top-6 right-0 left-0 fw5-sm flex justify-center absolute font-cm-sm md:relative md:top-0 md:text-left md:block";

const DialogTitle = ({
  className = "",
  children,
  shouldAnchorToTop = false,
  ...props
}: React.HTMLAttributes<HTMLSpanElement> & {shouldAnchorToTop?: boolean}): React.ReactElement => (
  <h2
    className={`font-csb text-[1.25rem] md:text-[2rem] leading-none ${
      shouldAnchorToTop ? anchorToTop : ""
    } ${className}`}
    {...props}
  >
    {children}
  </h2>
);

export default React.memo(DialogTitle);
